import { useState } from "react";

import { css } from "@emotion/react";

import Image from "next/image";
import { useRouter } from "next/router";

import { TopNav } from "components/shared/layout/TopNav";
import { Button } from "components/shared/library/Button";
import { DropdownDeprecated } from "components/shared/library/DropdownDeprecated";
import { RectangleToast } from "components/shared/library/RectangleToast";
import { Typography } from "components/shared/library/Typography";

import { useAuth } from "contexts/authContext";

import { ResumeFeedback } from "apis/resumeFeedback";

import { down } from "utils/mediaQueryStrings";
import { getSignUpUrl } from "utils/urls";

import { white } from "constants/colors";
import {
  publicSampleResumeNames,
  publicSampleResumeOptions,
  publicSampleResumes,
} from "constants/resume";

import { FeedbackDisplay } from "./FeedbackDisplay";

const style = {
  wrapper: css({
    margin: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
    width: "-webkit-fill-available",
  }),
  card: css({
    display: "flex",
    flexDirection: "column",
    gap: 24,
    width: "-webkit-fill-available",
    background: white,
    borderRadius: 16,
    padding: 24,
  }),
  signupCard: css({
    display: "flex",
    gap: 24,
    width: "-webkit-fill-available",
    background: white,
    borderRadius: 16,
    padding: 24,
    alignItems: "center",
    justifyContent: "space-between",
    [down("mobile")]: {
      flexDirection: "column",
    },
  }),
  signupCardText: css({
    display: "flex",
    flexDirection: "column",
    gap: 8,
  }),
  innerSignUpCard: css({
    display: "flex",
    gap: 24,
    alignItems: "center",
  }),
};

export const ResumePublicPage = () => {
  const router = useRouter();
  const { user } = useAuth();

  const [feedback, setFeedback] = useState<ResumeFeedback>();
  const [firstName, setFirstName] = useState<string>();

  return (
    <TopNav pageTitle="Resume+">
      <div css={style.wrapper}>
        <RectangleToast title="This is an example of the feedback you can expect from Resume+." />

        <div css={style.card}>
          <Typography size="M" weight="bold">
            Select a role
          </Typography>
          <Typography size="S">
            Choose a role that you are interested in to see example resume
            feedback
          </Typography>

          <DropdownDeprecated
            options={publicSampleResumeOptions}
            label="Select a role"
            displayLabel={false}
            onChange={(value) => {
              setFeedback(
                publicSampleResumes[value as keyof typeof publicSampleResumes]
              );
              setFirstName(
                publicSampleResumeNames[
                  value as keyof typeof publicSampleResumeNames
                ]
              );
            }}
            placeholder="Select a role to see an example resume and feedback"
          />
        </div>

        {!user?.id && (
          <div css={style.signupCard}>
            <div css={style.innerSignUpCard}>
              <Image
                src="/images/resume-plus-smiley.png"
                width={80}
                height={80}
                alt="Resume+"
              />
              <div css={style.signupCardText}>
                <Typography size="M" weight="bold">
                  Stand out as a candidate
                </Typography>
                <Typography size="S">
                  Sign up to use Resume+ and get personalized feedback on your
                  resume
                </Typography>
              </div>
            </div>

            <Button onClick={() => router.push(getSignUpUrl({}))}>
              Sign up
            </Button>
          </div>
        )}

        {feedback && firstName && (
          <FeedbackDisplay feedback={feedback} firstName={firstName} />
        )}
      </div>
    </TopNav>
  );
};
